export const PersonalDetailsState = {
  id: "",
  first_name: "",
  last_name: "",
  date_of_birth: "",
  phone_number: "",
  email_id: "",
  addresses: {
    id: "",
    address_type: "",
    address_line_1: "",
    address_line_2: "",
    country: "",
    state: "",
    city: "",
    pin: "",
  },
  linkedin: "",
  github: "",
  date_format: "",
  currentPassword: "",
  password: "",
  confirmPassword: "",
};
